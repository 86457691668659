import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import FlutterCallback from './FlutterCallback';
import WebView from './WebView';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<FlutterCallback />} />
                <Route path="/webview" element={<WebView />} />
            </Routes>
        </Router>
    );
}

export default App;