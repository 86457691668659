import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";
import moment from "moment";
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';

function FlutterCallback() {
    const navigate = useNavigate();

    const actionInit = 'init';
    const actionLogin = 'login';
    const actionLoginResponse = 'loginResponse';
    const actionLogout = 'logout';
    const actionRefresh = 'refresh';
    const actionCloseWeb = 'closeweb';
    const actionRequestNotificationPermission = 'requestNotificationPermission';
    const actionNotificationPermissionResponse = 'notificationPermissionResponse';
    const actionRequestCameraPermission = 'requestCameraPermission';
    const actionCameraPermissionResponse = 'cameraPermissionResponse';
    const actionBack = 'back';
    const actionPrinterRegistration = 'printerRegistration';
    const actionPrinterRegistrationResponse = 'printerRegistrationResponse';
    const actionPrinterTest = 'printerTest';
    const actionPrinterTestResponse = 'printerTestResponse';
    const actionPrinterConnection = 'printerConnection';
    const actionPrinterConnectionResponse = 'printerConnectionResponse';
    const actionPrintOrderRequest = 'printOrderRequest';
    const actionPrintOrderResponse = 'printOrderResponse';
    const actionOpenCamera = 'openCamera';
    const actionPrintReceiptRequest = 'printReceiptRequest';
    const actionPrintReceiptResponse = 'printReceiptResponse';
    const actionPrintReceiptByImageRequest = 'printReceiptByImageRequest';
    const actionPrintReceiptByImageResponse = 'printReceiptByImageResponse';
    const actionOpenUrlRequest = 'openUrlRequest';
    const actionOpenUrlResponse = 'openUrlResponse';

    const [messageFromFlutter, setMessageFromFlutter] = useState('No message received');
    const [logs, setLogs] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [intervalEnabled, setIntervalEnabled] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    // 새로운 로그를 추가하는 함수
    const addLog = (newLog) => {
        setLogs((prevLogs) => [...prevLogs, '[' + moment().format('HH:mm:ss.SSS') + '] ' + newLog]);

        console.log(newLog);
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    const start = () => {
        if(mounted) {
            return;
        }

        // 로그 시작
        addLog('Start logging...');
        addLog('');

        addLog('컴포넌트 mounted');

        window.handleMessageFromFlutter = handleMessageFromFlutter; // 전역 객체에 함수 등록

        // notify flutter of page loading is done.
        init();
    }

    // useEffect 훅을 사용하여 함수 컴포넌트의 함수를 전역으로 노출
    useEffect(() => {

        setTimeout(() => {
            start();
        }, 1000);

        // 10초마다 printOrderRequest 호출하는 interval 설정
        let intervalId;
        
        // 페이지 로딩이 완료된 후(mounted가 true가 된 후)에 interval 시작
        const startPrintInterval = () => {
            intervalId = setInterval(() => {
                printOrderRequest();
                addLog('30초 간격 printOrderRequest 호출');
            }, 30000);
        };

        // intervalEnabled 상태에 따라 interval 시작/중지
        if (mounted && intervalEnabled) {
            startPrintInterval();
            addLog('자동 호출 시작됨');
        }

        // 컴포넌트 언마운트 시 정리
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
            addLog('컴포넌트 unmounted');
        };
    }, [mounted, intervalEnabled]); // intervalEnabled 의존성 추가

    const onLogin = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);

        loginResponse();
    }

    const onNotificationPermissionResponse = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);
    }

    const onBack = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);

        // console.log('back is sent');
    }

    const onPrinterRegistration = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);
    }

    const onPrinterTestResponse = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);
    }

    const onPrinterConnectionResponse = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);
    }

    const onPrintOrderResponse = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);
    }

    const onCameraPermissionResponse = (data) => {
        const strData = JSON.stringify(data);
        addLog('카메라 권한 응답: ' + strData);
        console.log('카메라 권한 응답: ' + strData);

        if (data.status === true) {
            openCamera();
        } else {
            addLog('카메라 권한이 거부되었습니다.');
            alert('카메라 권한이 없습니다.');
        }
    }

    const openCamera = () => {
        addLog('웹 카메라 오픈 시도...');
        
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                addLog('카메라 접근 승인됨');
                
                const videoElement = document.getElementById('video');
                const captureButton = document.getElementById('capture-button');
                const canvas = document.getElementById('canvas');
                const photo = document.getElementById('photo');  // photo 엘리먼트 가져오기

                videoElement.srcObject = stream;
                videoElement.autoplay = true;
                videoElement.playsinline = true;

                // 촬영 버튼 활성화 및 canvas, photo 표시
                captureButton.disabled = false;
                canvas.style.display = 'block';
                photo.style.display = 'block';  // photo 엘리먼트를 보이게 설정
            })
            .catch((err) => {
                addLog('카메라 접근 오류: ' + err.message);
                console.error('카메라 접근 오류:', err);
            });
    };

    const capturePhoto = () => {
        console.log('capturePhoto 함수 호출');

        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
        const photo = document.getElementById('photo');
        const context = canvas.getContext('2d');

        // 캔버스 크기를 비디오 크기에 맞춤
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // 비디오 프레임을 캔버스에 그리기
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // 캔버스의 이미지를 photo 엘리먼트에 표시
        const dataUrl = canvas.toDataURL('image/png');
        photo.src = dataUrl;
        photo.style.display = 'block';

        console.log('capturePhoto 완료');
    };

    // Flutter --> React.js 로 함수 호출
    const handleMessageFromFlutter = (message) => {

        // jsonMessage = {type: "login", data: "this is a fcm token"}
        const jsonMessage = JSON.stringify(message);

        addLog('Flutter 에서 받은 메시지 : ' + jsonMessage);

        // console.log(jsonMessage);

        setMessageFromFlutter(jsonMessage);

        const data = message.data;
        const action = message.action;

        switch (action) {
            case actionInit:
                onLogin(data);
                break;
            case actionNotificationPermissionResponse:
                onNotificationPermissionResponse(data);
                break;
            case actionBack:
                onBack(data);
                break;
            case actionPrinterRegistration:
                onPrinterRegistration(data);
                break;
            case actionPrinterTestResponse:
                onPrinterTestResponse(data);
                break;
            case actionPrinterConnectionResponse:
                onPrinterConnectionResponse(data);
                break;
            case actionPrintOrderResponse:
                onPrintOrderResponse(data);
                break;
            case actionCameraPermissionResponse:
                console.log(actionCameraPermissionResponse + ' 호출');
                onCameraPermissionResponse(data);
                break;
            case actionPrintReceiptResponse:
                onPrintReceiptResponse(data);
                break;
            case actionPrintReceiptByImageResponse:
                onPrintReceiptByImageResponse(data);
                break;
            case actionOpenUrlResponse:
                onOpenUrlResponse(data);
                break;
            default:
                break;
        }
    }

    const init = () => {
        // 객체 생성
        const messageObject = {
            action: actionInit,
            platfom: 'web',
            data: {
                status: true
            }
        };

        addLog('init 함수 호출');

        sendMessageToFlutter(messageObject);
    }

    const loginResponse = () => {
        const messageObject = {
            action: actionLoginResponse,
            os: "web",  // 또는 "app"
            data: {
                status: true
            }
        };

        addLog('loginResponse 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const requestNotificationPermission = () => {
        const messageObject = {
            action: actionRequestNotificationPermission,
            os: "web",  // 또는 "app"
            data: {
                // status: true
            }
        };

        addLog('requestNotificationPermission 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const logout = () => {
        const messageObject = {
            action: actionLogout,
            os: "web",  // 또는 "app"
            data: {
                // status: true
            }
        };

        addLog('logout 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const refresh = () => {
        const messageObject = {
            action: actionRefresh,
            os: "web",  // 또는 "app"
            data: {
                url: 'https://www.oqoq.ai/main/tab/order?tab=new'
            }
        };

        addLog('refresh 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const closeWeb = () => {
        const messageObject = {
            action: actionCloseWeb,
            os: "web",  // 또는 "app"
            data: {
                // status: true
            }
        };

        addLog('closeWeb 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const goDaum = () => {
        document.location.href = 'https://www.daum.net';
    }

    // React.js --> Flutter 로 함수 호출.
    const sendMessageToFlutter = (messageObject) => {

        // 객체를 JSON 문자열로 변환하여 Flutter로 전달
        if(window.flutterChannel) {
            window.flutterChannel.postMessage(JSON.stringify(messageObject));
        }
        else {
            addLog('Flutter 채널이 없습니다.');
        }
    }

    // 프린터 테스트
    const printerTest = () => {
        const messageObject = {
            action: actionPrinterTest,
            os: "web",  // 또는 "app"
            data: {
                "name": "192.168.100.12",
                "device_name": "192.168.100.12:9100",
                "address": "192.168.100.12",
                "vendor_id": null,
                "product_id": null,
                "device_id": null,
                "attach_device_id": "SP1A.210812.016",
                "attach_device_os": "Android",
                "printer_connection_type": "wifi",
                "device": {
                    "menu_device_id_number": 100, 
                    "menu_reg_member_owner_id" : 100,
                }
            }
        };

        addLog('printerTest 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const printerConnection = () => {
        const messageObject = {
            action: actionPrinterConnection,
            os: "web",  // 또는 "app"
            data: {
                "name": "192.168.100.12",
                "device_name": "192.168.100.12:9100",
                "address": "192.168.100.12",
                "vendor_id": null,
                "product_id": null,
                "device_id": null,
                "attach_device_id": "SP1A.210812.016",
                "attach_device_os": "Android",
                "printer_connection_type": "wifi",
                "device": {
                    "menu_device_id_number": 100, 
                    "menu_reg_member_owner_id" : 100,
                }
            }
        };

        addLog('printerConnection 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const printOrderRequest = () => {

        console.log('printOrderRequest 함수 호출');
        const messageObject = {
            "action": "printOrderRequest",
            "os": "web",
            "data": {
                // "printer": {
                //     "name": "POS PRINTER(1004)",
                //     "device_name": "POS PRINTER",
                //     "address": null,
                //     "vendor_id": "1317",
                //     "product_id": "42752",
                //     "device_id": "1004",
                //     "attach_device_id": "SP1A.210812.016",
                //     "attach_device_os": "Android",
                //     "printer_connection_type": "usb"
                // },
                "printer": {
                    "name": "192.168.100.12",
                    "device_name": "192.168.100.12:9100",
                    "address": "192.168.100.12",
                    "vendor_id": null,
                    "product_id": null,
                    "device_id": null,
                    "attach_device_id": "SP1A.210812.016",
                    "attach_device_os": "Android",
                    "printer_connection_type": "wifi",
                    "device": {
                        "menu_device_id_number": 100, 
                        "menu_reg_member_owner_id" : 100,
                    }
                },
                "content": {
                    "header": {
                        "title": "주문 영수증",
                        "order_number": "6",
                        "total_order_number": 5
                    },
                    "items": [
                        {
                            "name": "호출",
                            "quantity": 1,
                            "price": "$10.00",
                            "options": [
                                {
                                    "name": "텔레그램!",
                                    "price": "$10.00"
                                }
                            ]
                        },
                        {
                            "name": "노래 그만 해주세요",
                            "quantity": 1,
                            "price": "$1000.00",
                            "options": [
                                {
                                    "name": "기본옵션",
                                    "price": "$1000.00"
                                }
                            ]
                        },
                        {
                            "name": "돈 벌어 와 주세요",
                            "quantity": 1,
                            "price": "$10000.00",
                            "options": [
                                {
                                    "name": "기본옵션",
                                    "price": "$10000.00"
                                }
                            ]
                        },
                        {
                            "name": "밥 사주세요",
                            "quantity": 1,
                            "price": "$60000.00",
                            "options": [
                                {
                                    "name": "기본옵션",
                                    "price": "$50000.00"
                                },
                                {
                                    "name": "한식",
                                    "price": "$10000.00"
                                }
                            ]
                        },
                        {
                            "name": "밥 사주세요",
                            "quantity": 1,
                            "price": "$60000.00",
                            "options": [
                                {
                                    "name": "한식",
                                    "price": "$10000.00"
                                },
                                {
                                    "name": "기본옵션",
                                    "price": "$50000.00"
                                }
                            ]
                        }
                    ],
                    "footer": {
                        "order_time": "2024. 11. 22. 오후 5:24:56",
                        "pos": "f83a18c3-4fb8-4159-acfa-11a374eac2fd",
                        "total_amount": "$131010.00"
                    }
                }
            }
        };

        addLog('printOrderRequest 함수 호출');
        sendMessageToFlutter(messageObject);
    };

    // printOrderRequestFiveTimes 함수 추가
    const printOrderRequestFiveTimes = () => {
        addLog('printOrderRequest 5회 호출 시작');
        for(let i = 0; i < 5; i++) {
            printOrderRequest();
        }
        addLog('printOrderRequest 5회 호출 완료');
    };

    // printOrderRequestOnce 함수 추가
    const printOrderRequestOnce = () => {
        addLog('printOrderRequest 1회 호출');
        printOrderRequest();
    };

    // interval 토글 함수 추가
    const toggleInterval = () => {
        setIntervalEnabled(!intervalEnabled);
        addLog(intervalEnabled ? '자동 호출 중지됨' : '자동 호출 시작됨');
    };

    const requestCameraPermission = () => {
        const messageObject = {
            action: actionRequestCameraPermission,
            os: "web",
            data: {}
        };

        addLog('requestCameraPermission 함수 호출');
        sendMessageToFlutter(messageObject);
    };

    const takePicture = () => {
        // Flutter 웹뷰 환경 체크
        if (window.flutterChannel) {
            // Flutter 웹뷰인 경우 권한 요청
            requestCameraPermission();
        } else {
            // 일반 웹 브라우저인 경우 직접 카메라 오픈
            addLog('일반 웹 환경에서 카메라 직접 오픈');
            openCamera();
        }
    }

    const printReceiptRequest = () => {
        const messageObject = {
            "action": "printReceiptRequest",
            "os": "web",
            "data": {
                "printer": {
                    "name": "192.168.100.12",
                    "device_name": "192.168.100.12:9100",
                    "address": "192.168.100.12",
                    "vendor_id": null,
                    "product_id": null,
                    "device_id": null,
                    "attach_device_id": "SP1A.210812.016",
                    "attach_device_os": "Android",
                    "printer_connection_type": "wifi",
                    "device": {
                        "menu_device_id_number": 100, 
                        "menu_reg_member_owner_id" : 100,
                    }
                },
                "content": {
                    "header": {
                        "table_no": "001",
                        "total_order_number": 2,
                        "total_amount": "29500원"
                    },
                    "items": [
                        {
                            "name": "가리비술찜과 연어알 파스타",
                            "quantity": 1,
                            "price": "21000원",
                            "options": [
                                {
                                    "name": "술찜 + 연어알 파스타",
                                    "price": "2000",
                                }
                            ]
                        },
                        {
                            "name": "80에라 애플 하이볼",
                            "quantity": 1,
                            "price": "8500원",
                            "options": [
                                {
                                    "name": "하이볼 40ml",
                                    "price": "2000",
                                }
                            ]
                        }
                    ],
                    "footer": {
                        'store_name': '목림밥 베이글 샌드위치 삼성역점',
                        'business_name': '제너디어스',
                        'business_number': '234-09-32456',
                        'owner': '서광운',
                        'phone': '1544-0909',
                        'address': '서울시 강남구 삼성동 53-1, 1층 101호',
                        'payment_time': moment().format('YYYY.MM.DD A h:mm:ss'),
                        'pos': 'AI 메뉴판 OQ'
                    }
                }
            }
        };

        addLog('printReceiptRequest 함수 호출');
        sendMessageToFlutter(messageObject);
    };

    const printReceiptRequestWithEmpty = () => {
        const messageObject = {
            "action": "printReceiptRequest",
            "os": "web",
            "data": {
                "printer": {
                    "name": "192.168.100.12",
                    "device_name": "192.168.100.12:9100",
                    "address": "192.168.100.12",
                    "vendor_id": null,
                    "product_id": null,
                    "device_id": null,
                    "attach_device_id": "SP1A.210812.016",
                    "attach_device_os": "Android",
                    "printer_connection_type": "wifi",
                    "device": {
                        "menu_device_id_number": 100, 
                        "menu_reg_member_owner_id" : 100,
                    }
                },
                "content": {
                    "header": {
                        "table_no": "001",
                        "total_order_number": 2,
                        "total_amount": "29500원"
                    },
                    "items": [
                        {
                            "name": "가리비술찜과 연어알 파스타",
                            "quantity": 1,
                            "price": "21000원",
                            "options": [
                                {
                                    "name": "술찜 + 연어알 파스타",
                                    "price": "2000",
                                }
                            ]
                        },
                        {
                            "name": "80에라 애플 하이볼",
                            "quantity": 1,
                            "price": "8500원",
                            "options": [
                                {
                                    "name": "하이볼 40ml",
                                    "price": "2000",
                                }
                            ]
                        }
                    ],
                    "footer": {
                        'store_name': '',
                        'business_name': null,
                        'business_number': '',
                        'owner': '서광운',
                        'phone': null,
                        'address': '서울시 강남구 삼성동 53-1, 1층 101호',
                        'payment_time': moment().format('YYYY.MM.DD A h:mm:ss'),
                        'pos': 'AI 메뉴판 OQ'
                    }
                }
            }
        };

        addLog('printReceiptRequestWithEmpty 함수 호출');
        sendMessageToFlutter(messageObject);
    };

    const onPrintReceiptResponse = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);
    }

    const printReceiptRequestByImage = async () => {
        setPreviewImage(null);

        const receiptDiv = document.createElement('div');
        receiptDiv.style.padding = '0';  // 외부 패딩 제거
        receiptDiv.style.backgroundColor = 'white';
        receiptDiv.style.width = '400px';
        receiptDiv.style.position = 'fixed';
        receiptDiv.style.top = '0';
        receiptDiv.style.left = '0';
        receiptDiv.style.zIndex = '-1000';
        
        // 영수증 내용 구성 - 내부 여백은 유지
        receiptDiv.innerHTML = `
            <div style="font-family: Arial, sans-serif; font-size: 16px; padding: 0;">
                <div style="text-align: center; font-size: 20px; font-weight: bold; margin-bottom: 15px;">
                    목림밥 베이글 샌드위치 삼성역점
                </div>
                <div style="border-bottom: 1px dashed #000; margin: 15px 0;"></div>
                <div style="font-size: 16px;">테이블: 001</div>
                <div style="font-size: 16px;">주문수량: 2</div>
                <div style="border-bottom: 1px dashed #000; margin: 15px 0;"></div>
                <div style="margin-bottom: 15px;">
                    <div style="font-weight: bold; font-size: 18px;">가리비술찜과 연어알 파스타</div>
                    <div style="display: flex; justify-content: space-between; font-size: 16px;">
                        <span>1개</span>
                        <span>21,000원</span>
                    </div>
                    <div style="padding-left: 15px; color: #666; font-size: 14px;">
                        - 술찜 + 연어알 파스타 (+2,000원)
                    </div>
                </div>
                <div style="margin-bottom: 15px;">
                    <div style="font-weight: bold; font-size: 18px;">80에라 애플 하이볼</div>
                    <div style="display: flex; justify-content: space-between; font-size: 16px;">
                        <span>1개</span>
                        <span>8,500원</span>
                    </div>
                    <div style="padding-left: 15px; color: #666; font-size: 14px;">
                        - 하이볼 40ml (+2,000원)
                    </div>
                </div>
                <div style="border-bottom: 1px dashed #000; margin: 15px 0;"></div>
                <div style="display: flex; justify-content: space-between; font-weight: bold; font-size: 18px;">
                    <span>합계</span>
                    <span>29,500원</span>
                </div>
                <div style="border-bottom: 1px dashed #000; margin: 15px 0;"></div>
                <div style="font-size: 14px; color: #666;">
                    <div>사업자: 제너디어스</div>
                    <div>사업자번호: 234-09-32456</div>
                    <div>대표자: 서광운</div>
                    <div>전화: 1544-0909</div>
                    <div>주소: 서울시 강남구 삼성동 53-1, 1층 101호</div>
                    <div>결제시각: ${moment().format('YYYY.MM.DD A h:mm:ss')}</div>
                    <div>POS: AI 메뉴판 OQ</div>
                </div>
            </div>
        `;

        document.body.appendChild(receiptDiv);

        try {
            await new Promise(resolve => setTimeout(resolve, 100));

            const canvas = await html2canvas(receiptDiv, {
                scale: 3,
                backgroundColor: 'white',
                logging: false,
                willReadFrequently: true,
                useCORS: true,
                allowTaint: true,
                removeContainer: false,
                margin: 0,
                x: 0,
                y: 0
            });

            const blob = await new Promise(resolve => {
                canvas.toBlob(resolve, 'image/png', 1.0);
            });

            if (!blob) {
                throw new Error('Failed to create image blob');
            }

            // 이미지 URL 생성 및 미리보기 설정
            const imageUrl = URL.createObjectURL(blob);
            setPreviewImage(imageUrl);

            // 웹뷰 환경 체크 수정
            const isWebView = !!window.flutterChannel;

            if (isWebView) {
                addLog('printReceiptRequestByImage 함수 호출 (웹뷰)');

                // 웹뷰 환경: 프린트 요청 전송
                const base64data = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        try {
                            const base64String = reader.result
                                .replace('data:image/png;base64,', '');
                            resolve(base64String);
                        } catch (error) {
                            reject(error);
                        }
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });

                const messageObject = {
                    "action": actionPrintReceiptByImageRequest,
                    "os": "web",
                    "data": {
                        "printer": {
                            "name": "192.168.100.12",
                            "device_name": "192.168.100.12:9100",
                            "address": "192.168.100.12",
                            "vendor_id": null,
                            "product_id": null,
                            "device_id": null,
                            "attach_device_id": "SP1A.210812.016",
                            "attach_device_os": "Android",
                            "printer_connection_type": "wifi",
                            "device": {
                                "menu_device_id_number": 100, 
                                "menu_reg_member_owner_id": 100,
                            }
                        },
                        "image": base64data
                    }
                };

                addLog('printReceiptRequestByImage 함수 호출 (웹뷰)');
                sendMessageToFlutter(messageObject);
            } else {
                // 순수 웹 환경: PNG 파일 다운로드
                addLog('printReceiptRequestByImage 함수 호출 (웹)');
                
                const downloadLink = document.createElement('a');
                downloadLink.href = imageUrl;
                downloadLink.download = `receipt_${moment().format('YYYYMMDD_HHmmss')}.png`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                addLog('PNG 파일 다운로드 완료 (웹)');
            }

        } catch (error) {
            addLog('이미지 생성 중 오류 발생: ' + error.message);
            console.error('이미지 생성 오류:', error);
        } finally {
            if (document.body.contains(receiptDiv)) {
                document.body.removeChild(receiptDiv);
            }
        }
    };

    const onPrintReceiptByImageResponse = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);
    }

    const onOpenUrlResponse = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);

        if (data.status) {
            addLog('URL 열기 성공');
        } else {
            addLog('URL 열기 실패: ' + data.message);
        }
    };

    const openUrl = () => {
        const messageObject = {
            action: actionOpenUrlRequest,
            os: "web",
            data: {
                url: 'https://www.google.com',
                name: 'Google'
            }
        };

        addLog('openUrl 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center p-4">
            <div style={{
                position: 'fixed',
                top: '20px',
                right: '20px',
                zIndex: 1000
            }}>
                <button
                    onClick={() => navigate('/webview')}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        border: 'none',
                        fontSize: '16px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                    }}
                >
                    웹뷰 테스트
                </button>
            </div>

            <h1 className="text-3xl font-bold text-gray-800 mb-6">Flutter-Web Communication</h1>

            <div style={{
                ...ContainerStyle, 
                display: 'flex', 
                alignItems: 'center', 
                gap: '10px',
                flexWrap: 'wrap',  // 필요시 줄바꿈
                justifyContent: 'center'
            }}>
                <button style={{
                    ...ButtonStyle,
                    backgroundColor: intervalEnabled ? '#dc3545' : '#28a745',
                    maxWidth: 'fit-content'  // interval 버튼은 내용에 맞게 조정
                }}
                        onClick={toggleInterval}
                >
                    자동호출 {intervalEnabled ? '중지' : '시작'}
                </button>
                <span style={{
                    fontSize: '16px', 
                    color: '#666',
                    padding: '5px 0'
                }}>
                    interval : 30 secs
                </span>
            </div>

            <p className="text-lg text-gray-600 bg-white p-4 rounded shadow mb-8">
                Message from flutter : {messageFromFlutter}
            </p>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={requestNotificationPermission}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : requestNotificationPermission
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={openUrl}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : openUrlRequest
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={logout}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : logout
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={refresh}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : refresh
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={closeWeb}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : closeWeb
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={goDaum}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : www.daum.net 으로 이동.
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={printerTest}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : printerTest
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={printerConnection}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : printerConnection
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={printReceiptRequest}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : printReceiptRequest
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={printReceiptRequestWithEmpty}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : printReceiptRequestWithEmpty
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={printReceiptRequestByImage}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : printReceiptRequestByImage
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={printOrderRequest}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : printOrderRequest
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={printOrderRequestOnce}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : printOrderRequest (1회)
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={printOrderRequestFiveTimes}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : printOrderRequest (5회)
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={takePicture}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    사진 촬영하기
                </button>
            </div>

            <p className="mt-4 text-sm text-gray-500">
                * Press the button to send a message to the Flutter app.
            </p>

            {previewImage && (
                <div style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                    <h3 style={{marginBottom: '10px'}}>생성된 영수증 이미지</h3>
                    <img 
                        src={previewImage} 
                        alt="Receipt Preview" 
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            border: '1px solid #ddd'
                        }}
                    />
                </div>
            )}

            <div>
                <h2>로그 출력:</h2>
                <div style={{whiteSpace: 'pre-wrap', border: '1px solid black', padding: '10px'}}>
                    {logs.map((log, index) => (
                        <div key={index}>
                            {log || <br/>}
                        </div>
                    ))}
                </div>
            </div>

            <div id="video-container" style={{
                marginTop: '20px',
                width: '100%',
                maxWidth: '640px',
                backgroundColor: '#000',
                borderRadius: '8px',
                overflow: 'hidden'
            }}>
                <video 
                    id="video" 
                    autoPlay 
                    playsInline 
                    style={{width: '100%', maxWidth: '640px'}}
                ></video>
            </div>

            <div style={ContainerStyle}>
                <button 
                    id="capture-button"
                    style={ButtonStyle}
                    onClick={capturePhoto}
                    disabled={true}
                    className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    촬영
                </button>
            </div>

            <canvas 
                id="canvas" 
                width="320"
                height="240"
                style={{
                    display: 'none',
                    border: '1px solid #ccc',
                    marginTop: '10px'
                }}
            ></canvas>

            <div style={{marginTop: '20px', width: '320px'}}>
                <img 
                    id="photo" 
                    alt="촬영된 사진" 
                    style={{
                        display: 'none',
                        width: '100%', 
                        borderRadius: '8px',
                        border: '1px solid #ccc'
                    }}
                />
            </div>
        </div>
    );
}

const ContainerStyle = {
    marginTop: '20px',
    marginBottom: '10px',
    width: '100%',  // 전체 너비 사용
    padding: '0 20px',  // 좌우 패딩 추가
    boxSizing: 'border-box',  // 패딩을 너비에 포함
    display: 'flex',
    justifyContent: 'center'  // 버튼을 중앙 정렬
}

const ButtonStyle = {
    padding: '10px 20px',  // 좌우 패딩 증가
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    height: 'auto',  // 높이를 자동으로 조정
    minHeight: '40px',  // 최소 높이 설정
    fontSize: '16px',   // 모바일에서도 읽기 좋은 크기로 조정
    textAlign: 'left',
    width: '100%',      // 부모 컨테이너의 너비에 맞춤
    maxWidth: '600px',  // 최대 너비 설정
    wordBreak: 'break-word',  // 긴 단어도 줄바꿈
    whiteSpace: 'normal',     // 자동 줄바꿈 허용
    lineHeight: '1.4',        // 줄 간격 조정
}

export default FlutterCallback;