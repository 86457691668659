import React, { useState, useEffect, useRef } from 'react';

function WebView() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newOption, setNewOption] = useState({ title: '' });
    const [customOptions, setCustomOptions] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        if (isModalOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isModalOpen]);

    const inputStyle = {
        width: '90%',
        padding: '8px 12px',
        border: '1px solid #d1d5db',
        borderRadius: '4px',
        fontSize: '16px',
        textAlign: 'left',
        marginBottom: '24px'
    };

    const labelStyle = {
        fontWeight: 'bold',
        marginBottom: '8px',
        textAlign: 'left'
    };

    const textareaStyle = {
        ...inputStyle,
        minHeight: '80px',
        resize: 'vertical'
    };

    const handleAddOption = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setNewOption({ title: '' });
    };

    const handleOptionSubmit = () => {
        if (newOption.title.trim()) {
            setCustomOptions([...customOptions, newOption.title]);
            setIsModalOpen(false);
            setNewOption({ title: '' });
        }
    };

    const Modal = () => {
        if (!isModalOpen) return null;

        const buttonStyle = {
            padding: '8px 16px',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            width: '43%',
            fontSize: '16px'
        };

        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000,
                padding: '0 16px'
            }}>
                <div style={{
                    backgroundColor: '#f3f4f6',
                    borderRadius: '8px',
                    width: '90vw',
                    padding: '20px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                }}>
                    <h2 style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        marginBottom: '20px',
                        color: '#1f2937'
                    }}>
                        새 옵션 추가
                    </h2>

                    <div style={{ 
                        marginBottom: '24px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <label style={{
                            display: 'block',
                            marginBottom: '8px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            width: '90%'
                        }}>
                            옵션명
                        </label>
                        <input
                            ref={inputRef}
                            type="text"
                            value={newOption.title}
                            onChange={(e) => setNewOption({...newOption, title: e.target.value})}
                            style={{
                                width: '90%',
                                padding: '8px 12px',
                                border: '1px solid #d1d5db',
                                borderRadius: '4px',
                                fontSize: '16px'
                            }}
                            placeholder="옵션명을 입력하세요"
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '16px',
                        marginTop: '24px'
                    }}>
                        <button
                            onClick={handleOptionSubmit}
                            style={{
                                ...buttonStyle,
                                backgroundColor: '#2563eb'
                            }}
                        >
                            확인
                        </button>
                        <button
                            onClick={handleModalClose}
                            style={{
                                ...buttonStyle,
                                backgroundColor: '#9ca3af'
                            }}
                        >
                            취소
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div style={{ textAlign: 'center', paddingTop: '32px' }}>
            <h1 style={{ 
                fontSize: '28px',
                fontWeight: 'bold',
                color: '#1f2937',
                marginBottom: '32px'
            }}>
                매장 정보 수정
            </h1>

            <div style={{
                width: '90%',
                margin: '0 auto',
                textAlign: 'center'
            }}>
                <div style={labelStyle}>매장명</div>
                <input 
                    type="text"
                    style={inputStyle}
                    placeholder="매장 이름을 입력하세요"
                />

                <div style={labelStyle}>업종</div>
                <input 
                    type="text"
                    style={inputStyle}
                    placeholder="예: 카페, 레스토랑"
                />

                <div style={labelStyle}>가게 설명</div>
                <textarea 
                    style={textareaStyle}
                    placeholder="매장에 대한 상세 설명을 입력하세요"
                />

                <div style={{
                    width: '100%',
                    textAlign: 'right',
                    marginBottom: '8px'
                }}>
                    <button
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            color: '#2563eb',
                            fontSize: '14px',
                            cursor: 'pointer',
                            border: 'none',
                            background: 'none',
                            marginRight: '5%',
                            padding: '4px 8px'
                        }}
                        onClick={() => setIsModalOpen(true)}
                    >
                        <span style={{ marginRight: '4px' }}>+</span>
                        새 옵션 추가
                    </button>
                </div>

                <div style={{
                    width: '90%',
                    margin: '0 auto 16px auto'
                }}>
                    {customOptions.map((option, index) => (
                        <div key={index} style={{
                            width: '100%',
                            textAlign: 'left',
                            fontSize: '16px',
                            color: '#4b5563',
                            marginBottom: '8px'
                        }}>
                            {option}
                        </div>
                    ))}
                </div>

                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <div style={{
                        width: '90%',
                        fontWeight: 'bold',
                        marginBottom: '8px',
                        textAlign: 'left'
                    }}>
                        주소
                    </div>
                    <input 
                        type="text"
                        style={{
                            width: '90%',
                            padding: '8px 12px',
                            border: '1px solid #d1d5db',
                            borderRadius: '4px',
                            fontSize: '16px',
                            textAlign: 'left',
                            marginBottom: '24px'
                        }}
                        placeholder="정확한 주소를 입력하세요"
                    />
                </div>

                <div style={labelStyle}>찾아오시는 길</div>
                <textarea 
                    style={textareaStyle}
                    placeholder="찾아오시는 길을 상세히 설명해주세요"
                />

                <div style={labelStyle}>전화번호</div>
                <input 
                    type="tel"
                    style={inputStyle}
                    placeholder="예: 02-1234-5678"
                />

                <div style={labelStyle}>네이버 플레이스</div>
                <input 
                    type="url"
                    style={inputStyle}
                    placeholder="네이버 플레이스 URL을 입력하세요"
                />

                <div style={labelStyle}>인스타그램</div>
                <input 
                    type="url"
                    style={inputStyle}
                    placeholder="인스타그램 URL을 입력하세요"
                />

                <div style={labelStyle}>영업시간</div>
                <textarea 
                    style={textareaStyle}
                    placeholder="영업시간을 상세히 입력해주세요"
                />

                <div style={labelStyle}>Wi-Fi 정보</div>
                <textarea 
                    style={textareaStyle}
                    placeholder="Wi-Fi SSID와 비밀번호를 입력해주세요"
                />

                <div style={labelStyle}>화장실 가는 길</div>
                <textarea 
                    style={textareaStyle}
                    placeholder="화장실 위치와 가는 방법을 설명해주세요"
                />

                <button
                    style={{
                        width: '90%',
                        padding: '12px',
                        backgroundColor: '#2563eb',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginTop: '16px',
                        marginBottom: '32px'
                    }}
                    onClick={() => {
                        // 저장 로직 추가
                    }}
                >
                    저장
                </button>
            </div>

            <Modal />
        </div>
    );
}

export default WebView; 